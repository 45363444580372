:root {
  body {
    &.hellspincom {
      &.dark,
      &.light {
        --cashierBackgorundColor: #05071A;
        --cashierInnerBackground: #1A1B29;
        --cashierTitleType: #FFFFFF;
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;

        --cashierBalanceTextColor: #FFF5D6;
        --cashierBalanceLineHeight: 140%;
        --cashierBalanceFontWeight: 700;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: #FCC211;
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: #FCC211;
        --cashierAmountBorderBottomColor: #FCC211;
        --cashierAmountFontSize: 50px;
        --cashierAmountFontWeight: 700;
        --cashierInputBackground: #1A1B29;
        --cashierInputBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);
        --cashierInputFontSize: 14px;
        --cashierLabelText: #FFF5D6;
        --cashierWithdrawalLabelText: #FFF5D6;
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: rgb(117, 117, 117);
        --cashierInputActiveBorder: #FCC211;
        --cashierInputActiveBackground: #1A1B29;
        --cashierInputBorderRadius: 5px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), rgba(255, 124, 126, 0.576471);
        --cashierInputErrorBorder: #E93E3A;

        --cashierButtonDisabled: #A8A8A8;
        --cashierButtonDisabledText: #E0E1EA;
        --cashierButtonDisabledOpacity: 0.5;
        --cashierButton: #FCC211;
        --cashierButtonText: #05071A;
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: rgba(255, 245, 214, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(0, 171, 48, 1);
        --cashierFinalScreenPendingText: rgba(0, 171, 48, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(233, 62, 58, 1);
        --cashierFinalScreenBackground: #1A1B29;
        --cashierConfirmationColor: #A8A8A8;
        --cashierConfirmationTextColor: #FFFFFF;
        --cashierConfirmationHeaderColor: #FFFFFF;
        --cashierConfirmationLineHeight: 145%;
        --cashierConfirmationHeaderFontSize: 21px;
        --cashierConfirmationHeaderMobileFontSize: 21px;
        --cashierConfirmationHeaderFontWeight: 600;
        --cashierConfirmationInfoBackground: #05071A;
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: #87FFC6;
        --cashierFinalScreenFailureImage: #FF7C7A;
        --cashierFinalScreenPendindImage: #FEC882;

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: #32333F;
        --cashierFavoriteAmountHover: #32333F;
        --cashierFavoriteAmountSelected: #FCC211;
        --cashierFavoriteAmountBorder: #32333F;
        --cashierFavoriteAmountBorderRadius: 77px;
        --cashierFavoriteAmountSelectedColor: '';

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(253, 224, 54, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(41, 37, 38, 1);

        --cashierCheckbox: #FFF5D6;
        --cashierCheckboxFontSize: 12px;
        --cashierCheckboxFontWeight: 500;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: rgba(255, 245, 214, 1);
        --cashierCheckboxColor: rgba(255, 245, 214, 1);
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: #E93E3A;
        --cashierErrorsBackground: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FF7C7A;
        --cashierErrorsBorder: #E93E3A;
        --cashierErrorsBorderRadius: initial;

        --cashierDropdownLabel: #FFF5D6;
        --cashierDropdownPlaceholder: rgb(117, 117, 117);
        --cashierDropdownBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);
        --cashierDropdownBackground: #1A1B29;
        --cashierDropdownColor: #FFFAF3;
        --cashierDropdownOptionHover: #32333F;
        --cashierDropdownColorHover: #FFFFFF;
        --cashierDropdownBorderRadius: 5px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 0;
        --cashierDropdownOptionsTopMargin: '';
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: #1A1B29;
        --cashierNewAccountBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);

        --cashierDescriptionText: #FFF5D6;
        --cashierIconColor: #FFFFFF;
        --cashierLoaderColor: #FFFFFF;
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: transparent;

        --cashierConfirmationInputsSecondaryColor: #8C8D95;
        --cashierFileUploadingIconDisplay: none;
      }
    }
  }
}
