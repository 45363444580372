:root {
  body {
    &.twentybet {
      &.dark,
      &.light {
        --cashierBackgorundColor: #0D1D34;
        --cashierInnerBackground: #1A3769;
        --cashierTitleType: #FFFFFF;
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;

        --cashierBalanceTextColor: #FFFFFF;
        --cashierBalanceLineHeight: 20px;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: #4DA533;
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: #4DA533;
        --cashierAmountBorderBottomColor: #4DA533;
        --cashierAmountFontSize: 48px;
        --cashierAmountFontWeight: 900;
        --cashierInputBackground: #3D4A5D;
        --cashierInputBoxShadow: initial;
        --cashierInputFontSize: 14px;
        --cashierLabelText: #6F7B8D;
        --cashierWithdrawalLabelText: #6F7B8D;
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: rgb(117, 117, 117);
        --cashierInputActiveBorder: #4DA533;
        --cashierInputActiveBackground: #3D4A5D;
        --cashierInputBorderRadius: 32px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: #3D4A5D;
        --cashierInputErrorBorder: #F84E4E;

        --cashierButtonDisabled: #E2E8EE;
        --cashierButtonDisabledText: #6F7B8D;
        --cashierButtonDisabledOpacity: 1;
        --cashierButton: #4DA533;
        --cashierButtonText: #FFFFFF;
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: #FFFFFF;
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(77, 165, 51, 1);
        --cashierFinalScreenPendingText: rgba(77, 165, 51, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(248, 78, 78, 1);
        --cashierFinalScreenBackground: #0D1D34;
        --cashierConfirmationColor: #FFFFFF;
        --cashierConfirmationTextColor: #FFFFFF;
        --cashierConfirmationHeaderColor: #FFFFFF;
        --cashierConfirmationLineHeight: 145%;
        --cashierConfirmationHeaderFontSize: 21px;
        --cashierConfirmationHeaderMobileFontSize: 21px;
        --cashierConfirmationHeaderFontWeight: 600;
        --cashierConfirmationInfoBackground: #1A3769;
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: #87FFC6;
        --cashierFinalScreenFailureImage: #FF7C7A;
        --cashierFinalScreenPendindImage: #FEC882;

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: #3D4A5D;
        --cashierFavoriteAmountHover: initial;
        --cashierFavoriteAmountSelected: #4DA533;
        --cashierFavoriteAmountBorder: initial;
        --cashierFavoriteAmountBorderRadius: 4px;
        --cashierFavoriteAmountSelectedColor: #FFFFFF;

        --cashierFavoriteAmountBonusBadgeBackgroundColor: #F2C94C;
        --cashierFavoriteAmountBonusBadgeColor: #3E4049;
        --cashierFavoriteAmountBonusBannerBackgroundColor: rgba(242, 201, 76, 1);
        --cashierFavoriteAmountBonusBannerColor: rgba(62, 64, 73, 1);

        --cashierCheckbox: #4DA533;
        --cashierCheckboxFontSize: 10px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: #FFFFFF;
        --cashierCheckboxColor: #3D4A5D;
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: #F84E4E;
        --cashierErrorsBackground: linear-gradient(0deg, rgba(13, 29, 52, 0.75), rgba(13, 29, 52, 0.75)), #F84E4E;
        --cashierErrorsBorder: #F84E4E;
        --cashierErrorsBorderRadius: 4px;

        --cashierDropdownLabel: #6F7B8D;
        --cashierDropdownPlaceholder: rgb(117, 117, 117);
        --cashierDropdownBoxShadow: initial;
        --cashierDropdownBackground: #3D4A5D;
        --cashierDropdownColor: #FFFFFF;
        --cashierDropdownOptionHover: #6F7B8D;
        --cashierDropdownColorHover: initial;
        --cashierDropdownBorderRadius: 32px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 32px;
        --cashierDropdownOptionsTopMargin: '';
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: #3D4A5D;
        --cashierNewAccountBoxShadow: none;

        --cashierDescriptionText: #FFFFFF;
        --cashierIconColor: #FFFFFF;
        --cashierLoaderColor: rgba(77, 165, 51, 1);
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: none;

        --cashierConfirmationInputsSecondaryColor: #9097A2;
        --cashierFileUploadingIconDisplay: initial;
      }
    }
  }
}
