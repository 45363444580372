:root {
  body {
    &.bizzocasinocom {
      &.light,
      &.dark {
        --cashierBackgorundColor: #2B1234;
        --cashierInnerBackground: #3C2944;
        --cashierTitleType: #FFB800;
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;

        --cashierBalanceTextColor: #BA9CB4;
        --cashierBalanceLineHeight: 140%;
        --cashierBalanceFontWeight: 700;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: #FFB800;
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: #FFB800;
        --cashierAmountBorderBottomColor: #FFB800;
        --cashierAmountFontSize: 50px;
        --cashierAmountFontWeight: 700;
        --cashierInputBackground: #3C2944;
        --cashierInputBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);
        --cashierInputFontSize: 14px;
        --cashierLabelText: #BA9CB4;
        --cashierWithdrawalLabelText: #BA9CB4;
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: rgb(117, 117, 117);
        --cashierInputActiveBorder: '';
        --cashierInputActiveBackground: #3C2944;
        --cashierInputBorderRadius: 5px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), #FF7C7A;
        --cashierInputErrorBorder: #FF7C7A;

        --cashierButtonDisabled: #BA9CB4;
        --cashierButtonDisabledText: #F8E1F3;
        --cashierButtonDisabledOpacity: 0.5;
        --cashierButton: linear-gradient(180deg, #BBFF50 0%, #0AB93A 100%);
        --cashierButtonText: #2B1234;
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 16px;
        --cashierFinalScreenInstruction: rgba(248, 225, 243, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(89, 214, 139, 1);
        --cashierFinalScreenPendingText: rgba(89, 214, 139, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(255, 124, 122, 1);
        --cashierFinalScreenBackground: #3C2944;
        --cashierConfirmationColor: rgba(255, 250, 243, 1);
        --cashierConfirmationTextColor: rgba(255, 250, 243, 1);
        --cashierConfirmationHeaderColor: rgba(255, 250, 243, 1);
        --cashierConfirmationLineHeight: 145%;
        --cashierConfirmationHeaderFontSize: 21px;
        --cashierConfirmationHeaderMobileFontSize: 21px;
        --cashierConfirmationHeaderFontWeight: 600;
        --cashierConfirmationInfoBackground: #3C2944;
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: #87FFC6;
        --cashierFinalScreenFailureImage: #FF7C7A;
        --cashierFinalScreenPendindImage: #FEC882;

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: #3C2944;
        --cashierFavoriteAmountHover: #F8E1F3;
        --cashierFavoriteAmountSelected: #F8E1F3;
        --cashierFavoriteAmountBorder: #3C2944;
        --cashierFavoriteAmountBorderRadius: 77px;
        --cashierFavoriteAmountSelectedColor: '';

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(255, 184, 0, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(36, 17, 44, 1);

        --cashierCheckbox: #F8E1F3;
        --cashierCheckboxFontSize: 10px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: #BA9CB4;
        --cashierCheckboxColor: #F8E1F3;
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: #FF7C7A;
        --cashierErrorsBackground: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FF7C7A;
        --cashierErrorsBorder: #FF7C7A;
        --cashierErrorsBorderRadius: '';

        --cashierDropdownLabel: #BA9CB4;
        --cashierDropdownPlaceholder: rgb(117, 117, 117);
        --cashierDropdownBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);
        --cashierDropdownBackground: #3C2944;
        --cashierDropdownColor: #FFFAF3;
        --cashierDropdownOptionHover: #BA9CB4;
        --cashierDropdownColorHover: #FFFAF3;
        --cashierDropdownBorderRadius: 5px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 0;
        --cashierDropdownOptionsTopMargin: '';
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: #3C2944;
        --cashierNewAccountBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);

        --cashierDescriptionText: #BA9CB4;
        --cashierIconColor: '';
        --cashierLoaderColor: #FFB800;
        --cashierArrowWrapperDisplay: flex;
        --cashierArrowIconColor: rgba(255, 184, 0, 1);

        --cashierConfirmationInputsSecondaryColor: #9097A2;
        --cashierFileUploadingIconDisplay: initial;
      }
    }
  }
}
