:root {
  body {
    &.ivibetcom {
      &.dark,
      &.light {
        --cashierBackgorundColor: #22323A;
        --cashierInnerBackground: #2B404A;
        --cashierTitleType: #FFFFFF;
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;

        --cashierBalanceTextColor: #FFFFFF;
        --cashierBalanceLineHeight: 140%;
        --cashierBalanceFontWeight: 700;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: #FF6B00;
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: #FF6B00;
        --cashierAmountBorderBottomColor: #FF6B00;
        --cashierAmountFontSize: 48px;
        --cashierAmountFontWeight: 900;
        --cashierInputBackground: #4E6976;
        --cashierInputBoxShadow: initial;
        --cashierInputFontSize: 14px;
        --cashierLabelText: #818E95;
        --cashierWithdrawalLabelText: #818E95;
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: #A6B4BB;
        --cashierInputActiveBorder: #00AA81;
        --cashierInputActiveBackground: #4E6976;
        --cashierInputBorderRadius: 32px;
        --cashierInputFontWeight: 500;
        --cashierInputErrorBackground: #4E6976;
        --cashierInputErrorBorder: #F84E4E;

        --cashierButtonDisabled: #818E95;
        --cashierButtonDisabledText: #DEDEDE;
        --cashierButtonDisabledOpacity: 0.5;
        --cashierButton: #00AA81;
        --cashierButtonText: #FFFFFF;
        --cashierButtonRadius: 24px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: #FFFFFF;
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(0, 170, 129, 1);
        --cashierFinalScreenPendingText: rgba(0, 170, 129, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(248, 78, 78, 1);
        --cashierFinalScreenBackground: #2B404A;
        --cashierConfirmationColor: #FFFFFF;
        --cashierConfirmationTextColor: #FFFFFF;
        --cashierConfirmationHeaderColor: #FFFFFF;
        --cashierConfirmationLineHeight: 145%;
        --cashierConfirmationHeaderFontSize: 21px;
        --cashierConfirmationHeaderMobileFontSize: 21px;
        --cashierConfirmationHeaderFontWeight: 600;
        --cashierConfirmationInfoBackground: #22323A;
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: #87FFC6;
        --cashierFinalScreenFailureImage: #FF7C7A;
        --cashierFinalScreenPendindImage: #FEC882;

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: #2B404A;
        --cashierFavoriteAmountHover: initial;
        --cashierFavoriteAmountSelected: #FF6B00;
        --cashierFavoriteAmountBorder: initial;
        --cashierFavoriteAmountBorderRadius: 4px;
        --cashierFavoriteAmountSelectedColor: #FFFFFF;

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(242, 201, 76, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(70, 80, 87, 1);

        --cashierCheckbox: #00AA81;
        --cashierCheckboxFontSize: 10px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: #FFFFFF;
        --cashierCheckboxColor: #2B404A;
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: #F84E4E;
        --cashierErrorsBackground: linear-gradient(0deg, rgba(34, 50, 58, 0.75), rgba(34, 50, 58, 0.75)), #F84E4E;
        --cashierErrorsBorder: #F84E4E;
        --cashierErrorsBorderRadius: 4px;

        --cashierDropdownLabel: #818E95;
        --cashierDropdownPlaceholder: #A6B4BB;
        --cashierDropdownBoxShadow: initial;
        --cashierDropdownBackground: #4E6976;
        --cashierDropdownColor: #FFFFFF;
        --cashierDropdownOptionHover: #00AA81;
        --cashierDropdownColorHover: #FFFFFF;
        --cashierDropdownBorderRadius: 32px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 32px;
        --cashierDropdownOptionsTopMargin: 2px;
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: #4E6976;
        --cashierNewAccountBoxShadow: initial;

        --cashierDescriptionText: #FFFFFF;
        --cashierIconColor: #FFFFFF;
        --cashierLoaderColor: #00AA81;
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: initial;

        --cashierConfirmationInputsSecondaryColor: #99A1A4;
        --cashierFileUploadingIconDisplay: initial;
      }
    }
  }
}
