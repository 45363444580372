:root {
  // Payment Image holder
  --paymentIconHolderBorder: #292526;
  // End payment Image holder

  --cashierCheckbox: #ffffff;
  // colors
  --chardonnay: #FEC882;
  --laPalma: #0FA810;
  --bittersweet: #FF6161;
  --jon: #361D1E;
  --mineShaft: #322E2F;
  --riverBed: #445058;
  --mischka: #cbd2de;
  --bermudaGray: #778ca9;
  --heather: #C3CCD9;
  --athensGray: #edf1f3;

  //Notice section
  --noticeTextColor: #D4C5B1;
  //end notice section

  // input setup
  --inputIconBackground: #F1E3D0;
  --inputIconColor: #322E2F;
  // end input setup
}
