:root {
  body {
    &.national {
      &.dark,
      &.light {
        --cashierBackgorundColor: #292526;
        --cashierInnerBackground: rgba(50,46,47, 0.7);
        --cashierTitleType: #FEC882;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;

        --cashierBalanceTextColor: #94897A;
        --cashierBalanceLineHeight: 140%;
        --cashierBalanceFontWeight: 700;

        --cashierCurrencyFontWeight: 400;
        --cashierCurrencyColor: #fec882;
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: #fec882;
        --cashierAmountBorderBottomColor: #fec882;
        --cashierAmountFontSize: 40px;
        --cashierAmountFontWeight: 400;
        --cashierInputBackground: #3f3a39;
        --cashierInputBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);
        --cashierInputFontSize: 14px;
        --cashierLabelText: #94897A;
        --cashierWithdrawalLabelText: #94897A;
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: rgb(117, 117, 117);
        --cashierInputActiveBorder: rgba(254, 200, 130, 1);
        --cashierInputActiveBackground: #4E4842;
        --cashierInputBorderRadius: 5px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: #361D1E;
        --cashierInputErrorBorder: #FF6161;

        --cashierButtonDisabled: #94897A;
        --cashierButtonDisabledText: #4E4842;
        --cashierButtonDisabledOpacity: 0.5;
        --cashierButton: linear-gradient(180deg, #FEC882 0%, #FF9F6C 100%);
        --cashierButtonText: #322E2F;
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 12px;
        --cashierFinalScreenInstruction: rgba(255, 250, 243, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(255, 250, 243, 1);
        --cashierFinalScreenPendingText: rgba(255, 250, 243, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: #FF7C7A;
        --cashierFinalScreenBackground: rgba(50,46,47, 0.7);
        --cashierConfirmationColor: #FEC882;
        --cashierConfirmationTextColor: #FFFAF3;
        --cashierConfirmationHeaderColor: #FFFAF3;
        --cashierConfirmationLineHeight: 145%;
        --cashierConfirmationHeaderFontSize: 21px;
        --cashierConfirmationHeaderMobileFontSize: 21px;
        --cashierConfirmationHeaderFontWeight: 600;
        --cashierConfirmationInfoBackground: #292526;
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: #87FFC6;
        --cashierFinalScreenFailureImage: #FF7C7A;
        --cashierFinalScreenPendindImage: #FEC882;

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: #322E2F;
        --cashierFavoriteAmountHover: #4E4842;
        --cashierFavoriteAmountSelected: #D4C5B1;
        --cashierFavoriteAmountBorder: #4E4842;
        --cashierFavoriteAmountBorderRadius: 77px;
        --cashierFavoriteAmountSelectedColor: initial;

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(253, 224, 54, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(41, 37, 38, 1);

        --cashierCheckbox: #FEC882;
        --cashierCheckboxFontSize: 10px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: #D4C5B1;
        --cashierCheckboxColor: #3F3A39;
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: #FF6161;
        --cashierErrorsBackground: #361D1E;
        --cashierErrorsBorder: #FF6161;
        --cashierErrorsBorderRadius: 0;

        --cashierDropdownLabel: #94897A;
        --cashierDropdownPlaceholder: rgb(117, 117, 117);
        --cashierDropdownBoxShadow: 0 3px 1px rgba(29,25,26,0.3),inset 0 1px 0 rgba(128,128,128,0.4);
        --cashierDropdownBackground: #3F3A39;
        --cashierDropdownColor: #D4C5B1;
        --cashierDropdownOptionHover: #94897A;
        --cashierDropdownColorHover: #D4C5B1;
        --cashierDropdownBorderRadius: 5px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 0;
        --cashierDropdownOptionsTopMargin: 0;
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: #3F3A39;
        --cashierNewAccountBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierDescriptionText: #FEC882;
        --cashierIconColor: #FEC882;
        --cashierLoaderColor: #FEC882;
        --cashierArrowWrapperDisplay: flex;
        --cashierArrowIconColor: #FEC882;

        --cashierConfirmationInputsSecondaryColor: #9D9B9B;
        --cashierFileUploadingIconDisplay: none;
      }
    }
  }
}
