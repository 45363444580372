:root {
  body {
    &.dragonslots {
      &.light,
      &.dark {
        --cashierBackgorundColor: rgba(19, 4, 4, 1);
        --cashierInnerBackground: rgba(65, 47, 47, 1);
        --cashierTitleType: rgba(255, 247, 46, 1);
        --cashierLogoBoxShadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: 1px solid rgba(19, 4, 4, 1);
        --paymentIconHolderBackground: rgba(255, 255, 255, 1);
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 700;
        --cashierTitleTypeLineHeight: 120%;

        --cashierBalanceTextColor: rgba(255, 255, 255, 1);
        --cashierBalanceLineHeight: 140%;
        --cashierBalanceFontWeight: 400;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: rgba(255, 247, 46, 1);
        --cashierAmountErrorColor: rgba(190, 62, 62, 1);
        --cashierAmountColor: rgba(255, 255, 255, 1);
        --cashierAmountBorderBottomColor: rgba(255, 247, 46, 1);
        --cashierAmountFontSize: 50px;
        --cashierAmountFontWeight: 700;
        --cashierInputBackground: rgba(65, 47, 47, 1);
        --cashierInputBoxShadow: 0px 3px 1px rgba(29, 25, 26, 0.3), inset 0px 1px 0px rgba(128, 128, 128, 0.4);
        --cashierInputFontSize: 14px;
        --cashierLabelText: rgba(137, 129, 129, 1);
        --cashierWithdrawalLabelText: rgba(137, 129, 129, 1);
        --cashierInputBorder: transparent;
        --cashierInputColor: rgba(255, 255, 255, 1);
        --cashierInputPlaceholder: rgba(137, 129, 129, 1);
        --cashierInputActiveBorder: rgba(255, 247, 46, 1);
        --cashierInputActiveBackground: rgba(65, 47, 47, 1);
        --cashierInputBorderRadius: 5px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), #FF7C7A;
        --cashierInputErrorBorder: rgba(190, 62, 62, 1);

        --cashierButtonDisabled: rgba(137, 129, 129, 1);
        --cashierButtonDisabledText: rgba(255, 255, 255, 1);
        --cashierButtonDisabledOpacity: none;
        --cashierButton: rgba(76, 182, 58, 1);
        --cashierButtonText: rgba(255, 255, 255, 1);
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: rgba(255, 255, 255, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(0, 152, 43, 1);
        --cashierFinalScreenPendingText: rgba(255, 247, 46, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(190, 62, 62, 1);
        --cashierFinalScreenBackground: none;
        --cashierConfirmationColor: rgba(255, 247, 46, 1);
        --cashierConfirmationTextColor: rgba(255, 255, 255, 1);
        --cashierConfirmationHeaderColor: rgba(255, 255, 255, 1);
        --cashierConfirmationLineHeight: 32px;
        --cashierConfirmationHeaderFontSize: 20px;
        --cashierConfirmationHeaderMobileFontSize: 20px;
        --cashierConfirmationHeaderFontWeight: 700;
        --cashierConfirmationInfoBackground: rgba(65, 47, 47, 1);
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: rgba(76, 182, 58, 1);
        --cashierFinalScreenFailureImage: rgba(190, 62, 62, 1);
        --cashierFinalScreenPendindImage: rgba(255, 146, 46, 1);

        --cashierFavoriteAmountTextShadow: none;
        --cashierFavoriteAmountTextFontWeight: 400;
        --cashierFavoriteAmountColor: rgba(255, 255, 255, 1);
        --cashierFavoriteAmount: rgba(65, 47, 47, 1);
        --cashierFavoriteAmountHover: rgba(65, 47, 47, 1);
        --cashierFavoriteAmountSelected: rgba(76, 182, 58, 1);
        --cashierFavoriteAmountBorder: none;
        --cashierFavoriteAmountBorderRadius: 8px;
        --cashierFavoriteAmountSelectedColor: rgba(255, 255, 255, 1);

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(255, 199, 0, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(68, 80, 88, 1);

        --cashierCheckbox: rgba(255, 255, 255, 1);
        --cashierCheckboxFontSize: 12px;
        --cashierCheckboxFontWeight: 500;
        --cashierCheckboxLineHeight: 100%;
        --cashierCheckboxText: rgba(137, 129, 129, 1);
        --cashierCheckboxColor: rgba(255, 255, 255, 1);
        --cashierCheckboxMarkColor: rgba(45, 13, 13, 1);
        --cashierCheckboxBoxShadow: none;

        --cashierErrorsColorFont: rgba(190, 62, 62, 1);
        --cashierErrorsBackground: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), #FF7C7A;
        --cashierErrorsBorder: rgba(190, 62, 62, 1);
        --cashierErrorsBorderRadius: none;

        --cashierDropdownLabel: rgba(137, 129, 129, 1);
        --cashierDropdownPlaceholder: rgba(137, 129, 129, 1);
        --cashierDropdownBoxShadow: none;
        --cashierDropdownBackground: rgba(65, 47, 47, 1);
        --cashierDropdownColor: rgba(255, 255, 255, 1);
        --cashierDropdownOptionHover: rgba(137, 129, 129, 1);
        --cashierDropdownColorHover: rgba(255, 255, 255, 1);
        --cashierDropdownBorderRadius: 4px;
        --cashierDropdownActiveBorderColor: rgba(255, 247, 46, 1);
        --cashierDropdownBorderRadiusCorner: 4px;
        --cashierDropdownOptionsTopMargin: none;
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: rgba(65, 47, 47, 1);
        --cashierNewAccountBoxShadow: none;

        --cashierDescriptionText: rgba(255, 255, 255, 1);
        --cashierIconColor: rgba(255, 255, 255, 1);
        --cashierLoaderColor: rgba(255, 247, 46, 1);
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: none;

        --cashierConfirmationInputsSecondaryColor: rgba(255, 255, 255, .5);
        --cashierFileUploadingIconDisplay: none;
      }
    }
  }
}
