:root {
  body {
    &.vave {
      &.dark,
      &.light {
        --cashierBackgorundColor: radial-gradient(99.1% 195.97% at 0% 0%, #35444E 0%, #10171D 100%);
        --cashierInnerBackground: rgba(16, 23, 29, 0.5);
        --cashierTitleType: #FFFFFF;
        --cashierLogoBoxShadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
        --cashierLogoBorder: #292526;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 45px;
        --cashierTitleTypeFontSize: 24px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 600;
        --cashierTitleTypeLineHeight: 30px;

        --cashierBalanceTextColor: #FFFFFF;
        --cashierBalanceLineHeight: 20px;

        --cashierCurrencyFontWeight: 400;
        --cashierCurrencyColor: #94aeb4;
        --cashierAmountErrorColor: #FF6161;
        --cashierAmountColor: #94aeb4;
        --cashierAmountBorderBottomColor: #94aeb4;
        --cashierAmountFontSize: 40px;
        --cashierAmountFontWeight: 400;
        --cashierInputBackground: rgba(16, 23, 29, 0.5);
        --cashierInputBoxShadow: none;
        --cashierInputFontSize: 14px;
        --cashierLabelText: #94AEB4;
        --cashierWithdrawalLabelText: #94AEB4;
        --cashierInputBorder: transparent;
        --cashierInputColor: #FFFAF3;
        --cashierInputPlaceholder: #94AEB4;
        --cashierInputActiveBorder: 1px solid #2BC69D;
        --cashierInputActiveBackground: rgba(16, 23, 29, 0.5);
        --cashierInputBorderRadius: 8px;
        --cashierInputFontWeight: 700;
        --cashierInputErrorBackground: rgba(16, 23, 29, 0.5);
        --cashierInputErrorBorder: #FF4E4E;

        --cashierButtonDisabled: #22474F;
        --cashierButtonDisabledText: #94AEB4;
        --cashierButtonDisabledOpacity: 0.5;
        --cashierButton: #2BC69D;
        --cashierButtonText: #FFFFFF;
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: uppercase;
        --cashierFinalScreenInstructionFontSize: 12px;
        --cashierFinalScreenInstruction: rgba(43, 198, 157, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(148, 174, 180, 1);
        --cashierFinalScreenPendingText: rgba(148, 174, 180, 1);
        --cashierFinalScreenTextSize: 14px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(43, 198, 157, 1);
        --cashierFinalScreenBackground: rgba(16, 23, 29, 0.5);
        --cashierConfirmationColor: #FFFAF3;
        --cashierConfirmationTextColor: #FFFAF3;
        --cashierConfirmationHeaderColor: #FFFAF3;
        --cashierConfirmationLineHeight: 145%;
        --cashierConfirmationHeaderFontSize: 21px;
        --cashierConfirmationHeaderMobileFontSize: 21px;
        --cashierConfirmationHeaderFontWeight: 600;
        --cashierConfirmationInfoBackground: rgba(16, 23, 29, 0.5);
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: #87FFC6;
        --cashierFinalScreenFailureImage: #FF7C7A;
        --cashierFinalScreenPendindImage: #FEC882;

        --cashierFavoriteAmountTextShadow: 0 1px 1px rgba(0, 0, 0, .25);
        --cashierFavoriteAmountTextFontWeight: 600;
        --cashierFavoriteAmountColor: #fffaf3;
        --cashierFavoriteAmount: #22474F;
        --cashierFavoriteAmountHover: #2BC69D;
        --cashierFavoriteAmountSelected: #2BC69D;
        --cashierFavoriteAmountBorder: #22474F;
        --cashierFavoriteAmountBorderRadius: 77px;
        --cashierFavoriteAmountSelectedColor: '';

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(16, 23, 29, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(246, 194, 60, 1);

        --cashierCheckbox: #2BC69D;
        --cashierCheckboxFontSize: 10px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: #94aeb4;
        --cashierCheckboxColor: #35444E;
        --cashierCheckboxMarkColor: #322E2F;
        --cashierCheckboxBoxShadow: 0 3px 1px rgba(29, 25, 26, 0.3), inset 0 1px 0 rgba(128, 128, 128, 0.4);

        --cashierErrorsColorFont: #FFFFFF;
        --cashierErrorsBackground: #FF4E4E;
        --cashierErrorsBorder: #FF4E4E;
        --cashierErrorsBorderRadius: 8px;

        --cashierDropdownLabel: #94aeb4;
        --cashierDropdownPlaceholder: #94AEB4;
        --cashierDropdownBoxShadow: none;
        --cashierDropdownBackground: #171E26;
        --cashierDropdownColor: #94AEB4;
        --cashierDropdownOptionHover: rgba(53, 68, 78, 0.2);
        --cashierDropdownColorHover: #FFFFFF;
        --cashierDropdownBorderRadius: 5px;
        --cashierDropdownActiveBorderColor: transparent;
        --cashierDropdownBorderRadiusCorner: 0;
        --cashierDropdownOptionsTopMargin: '';
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: #10171D;
        --cashierNewAccountBoxShadow: none;

        --cashierDescriptionText: rgba(148, 174, 180, 1);
        --cashierIconColor: #FFFAF3;
        --cashierLoaderColor: #FFFAF3;
        --cashierArrowWrapperDisplay: flex;
        --cashierArrowIconColor: #2BC69D;
      }
    }
  }
}
