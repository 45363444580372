:root {
  body {
    &.tonywinae {
      &.dark,
      &.light {
        --cashierBackgorundColor: rgba(228, 231, 240, 1);
        --cashierInnerBackground: rgba(255, 255, 255, 1);
        --cashierTitleType: rgba(77, 94, 128, 1);
        --cashierLogoBoxShadow: initial;
        --cashierLogoBorder: transparent;
        --paymentIconHolderBackground: #FFF;
        --cashierTitleContainerBottomMargin: 24px;
        --cashierTitleTypeFontSize: 32px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 800;
        --cashierTitleTypeLineHeight: 40px;

        --cashierBalanceTextColor: rgba(77, 94, 128, 1);
        --cashierBalanceLineHeight: 20px;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: rgba(65, 12, 114, 1);
        --cashierAmountErrorColor: rgba(232, 69, 99, 1);
        --cashierAmountColor: rgba(77, 94, 128, 1);
        --cashierAmountBorderBottomColor: rgba(167, 39, 132, 1);
        --cashierAmountFontSize: 48px;
        --cashierAmountFontWeight: 900;
        --cashierInputBackground: rgba(255, 255, 255, 1);
        --cashierInputBoxShadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
        --cashierInputFontSize: 12px;
        --cashierLabelText: rgba(125, 143, 179, 1);
        --cashierWithdrawalLabelText: rgba(125, 143, 179, 1);
        --cashierInputBorder: transparent;
        --cashierInputColor: rgba(125, 143, 179, 1);
        --cashierInputPlaceholder: initial;
        --cashierInputActiveBorder: rgba(61, 195, 131, 1);
        --cashierInputActiveBackground: rgba(247, 248, 250, 1);
        --cashierInputBorderRadius: 8px;
        --cashierInputFontWeight: 600;
        --cashierInputErrorBackground: rgba(255, 255, 255, 1);
        --cashierInputErrorBorder: rgba(232, 69, 99, 1);

        --cashierButtonDisabled: rgba(65, 12, 114, 1);
        --cashierButtonDisabledText: rgba(255, 255, 255, 1);
        --cashierButtonDisabledOpacity: 0.3;
        --cashierButton: rgba(65, 12, 114, 1);
        --cashierButtonText: rgba(255, 255, 255, 1);
        --cashierButtonRadius: 5px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: rgba(125, 143, 179, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenText: rgba(61, 195, 131, 1);
        --cashierFinalScreenPendingText: rgba(61, 195, 131, 1);
        --cashierFinalScreenTextSize: 18px;
        --cashierFinalScreenTextFontSize: 700;
        --cashierFinalScreenMobileTextSize: 16px;
        --cashierFinalScreenErrorText: rgba(232, 69, 99, 1);
        --cashierFinalScreenBackground: transparent;
        --cashierConfirmationColor: rgba(77, 94, 128, 1);
        --cashierConfirmationTextColor: rgba(77, 94, 128, 1);
        --cashierConfirmationHeaderColor: rgba(77, 94, 128, 1);
        --cashierConfirmationLineHeight: 24px;
        --cashierConfirmationHeaderFontSize: 18px;
        --cashierConfirmationHeaderMobileFontSize: 16px;
        --cashierConfirmationHeaderFontWeight: 700;
        --cashierConfirmationInfoBackground: rgba(255, 255, 255, 1);
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: rgba(61, 195, 131, 1);
        --cashierFinalScreenFailureImage: rgba(232, 69, 99, 1);
        --cashierFinalScreenPendindImage: rgba(195, 202, 217, 1);

        --cashierFavoriteAmountTextShadow: initial;
        --cashierFavoriteAmountTextFontWeight: 400;
        --cashierFavoriteAmountColor: rgba(65, 12, 114, 1);
        --cashierFavoriteAmount: rgba(255, 255, 255, 1);
        --cashierFavoriteAmountHover: initial;
        --cashierFavoriteAmountSelected: rgba(65, 12, 114, 1);
        --cashierFavoriteAmountBorder: initial;
        --cashierFavoriteAmountBorderRadius: 4px;
        --cashierFavoriteAmountSelectedColor: rgba(255, 255, 255, 1);

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(255, 199, 0, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(30, 37, 50, 1);

        --cashierCheckbox: rgba(61, 195, 131, 1);
        --cashierCheckboxFontSize: 12px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 18px;
        --cashierCheckboxText: rgba(125, 143, 179, 1);
        --cashierCheckboxColor: rgba(255, 255, 255, 1);
        --cashierCheckboxMarkColor: #FFF;
        --cashierCheckboxBoxShadow: initial;

        --cashierErrorsColorFont: rgba(232, 69, 99, 1);
        --cashierErrorsBackground: rgba(232, 69, 99, 0.10);
        --cashierErrorsBorder: none;
        --cashierErrorsBorderRadius: 8px;

        --cashierDropdownLabel: rgba(125, 143, 179, 1);
        --cashierDropdownPlaceholder: rgba(125, 143, 179, .5);
        --cashierDropdownBoxShadow: initial;
        --cashierDropdownBackground: rgba(255, 255, 255, 1);
        --cashierDropdownColor: rgba(125, 143, 179, 1);
        --cashierDropdownOptionHover: rgba(247, 248, 250, 1);
        --cashierDropdownColorHover: rgba(125, 143, 179, 1);
        --cashierDropdownBorderRadius: 8px;
        --cashierDropdownActiveBorderColor: rgba(61, 195, 131, 1);
        --cashierDropdownBorderRadiusCorner: 8px;
        --cashierDropdownOptionsTopMargin: 2px;
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: rgba(255, 255, 255, 1);
        --cashierNewAccountBoxShadow: initial;

        --cashierDescriptionText: rgba(77, 94, 128, 1);
        --cashierIconColor: rgba(167, 39, 132, 1);
        --cashierLoaderColor: rgba(65, 12, 114, 1);
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: initial;
      }
    }
  }
}
