:root {
  body {
    &.granawincom {
      &.dark,
      &.light {
        --cashierBackgorundColor: rgba(0, 0, 0, 1);
        --cashierInnerBackground: rgba(48, 48, 48, 1);
        --cashierTitleType: rgba(255, 255, 255, 1);
        --cashierLogoBoxShadow: none;
        --cashierLogoBorder: 1px solid rgba(238, 241, 242, 1);
        --paymentIconHolderBackground: rgba(255, 255, 255, 1);
        --cashierTitleContainerBottomMargin: 16px;
        --cashierTitleTypeFontSize: 32px;
        --cashierTitleTypeMobileFontSize: 24px;
        --cashierTitleTypeFontWeight: 900;
        --cashierTitleTypeLineHeight: 40px;

        --cashierBalanceTextColor: rgba(255, 255, 255, 1);
        --cashierBalanceLineHeight: 20px;

        --cashierCurrencyFontWeight: 700;
        --cashierCurrencyColor: rgba(12, 229, 160, 1);
        --cashierAmountErrorColor: rgba(248, 78, 78, 1);
        --cashierAmountColor: rgba(255, 255, 255, 1);
        --cashierAmountBorderBottomColor: #4DA533;;
        --cashierAmountFontSize: 48px;
        --cashierAmountFontWeight: 900;
        --cashierInputBackground: rgba(48, 48, 48, 1);
        --cashierInputBoxShadow: none;
        --cashierInputFontSize: 14px;
        --cashierLabelText: rgba(129, 142, 149, 1);
        --cashierWithdrawalLabelText: rgba(129, 142, 149, 1);
        --cashierInputBorder: transparent;
        --cashierInputColor: rgba(255, 255, 255, 1);
        --cashierInputPlaceholder: rgba(255, 255, 255, .5);
        --cashierInputActiveBorder: rgba(12, 229, 160, 1);
        --cashierInputActiveBackground: rgba(48, 48, 48, 1);
        --cashierInputBorderRadius: 32px;
        --cashierInputFontWeight: 400;
        --cashierInputErrorBackground: rgba(48, 48, 48, 1);
        --cashierInputErrorBorder: rgba(248, 78, 78, 1);

        --cashierButtonDisabled: rgba(238, 241, 242, 1);
        --cashierButtonDisabledText: rgba(129, 142, 149, 1);
        --cashierButtonDisabledOpacity: .5;
        --cashierButton: rgba(12, 229, 160, 1);
        --cashierButtonText: rgba(0, 0, 0, 1);
        --cashierButtonRadius: 24px;

        --cashierFinalScreenInstructionTextTransformation: initial;
        --cashierFinalScreenInstructionFontSize: 14px;
        --cashierFinalScreenInstruction: rgba(255, 255, 255, 1);
        --cashierFinalScreenText: rgba(12, 229, 160, 1);
        --cashierFinalScreenPendingTextSize: 16px;
        --cashierFinalScreenPendingFontSize: 400;
        --cashierFinalScreenPendingText: rgba(12, 229, 160, 1);
        --cashierFinalScreenTextSize: 16px;
        --cashierFinalScreenTextFontSize: 400;
        --cashierFinalScreenMobileTextSize: 14px;
        --cashierFinalScreenErrorText: rgba(248, 78, 78, 1);
        --cashierFinalScreenBackground: rgba(0, 0, 0, 1);
        --cashierConfirmationColor: rgba(255, 255, 255, 1);
        --cashierConfirmationTextColor: rgba(255, 255, 255, 1);
        --cashierConfirmationHeaderColor: rgba(255, 255, 255, 1);
        --cashierConfirmationLineHeight: 32px;
        --cashierConfirmationHeaderFontSize: 20px;
        --cashierConfirmationHeaderMobileFontSize: 20px;
        --cashierConfirmationHeaderFontWeight: 700;
        --cashierConfirmationInfoBackground: rgba(48, 48, 48, 1);
        --cashierConfirmationInputsBackground: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
        --cashierConfirmationInputsBorderColor: hsla(0,0%,100%,.2);
        --cashierConfirmationInputsCopyIcon: rgba(255, 255, 255, 1);
        --cashierFinalScreenSuccessImage: rgba(12, 229, 160, 1);
        --cashierFinalScreenFailureImage: rgba(248, 78, 78, 1);
        --cashierFinalScreenPendindImage: rgba(12, 229, 160, 1);

        --cashierFavoriteAmountTextShadow: none;
        --cashierFavoriteAmountTextFontWeight: 400;
        --cashierFavoriteAmountColor: rgba(255, 255, 255, 1);
        --cashierFavoriteAmount: rgba(48, 48, 48, 1);
        --cashierFavoriteAmountHover: rgba(48, 48, 48, 1);
        --cashierFavoriteAmountSelected: rgba(12, 229, 160, 1);
        --cashierFavoriteAmountBorder: rgba(48, 48, 48, 1);
        --cashierFavoriteAmountBorderRadius: 4px;
        --cashierFavoriteAmountSelectedColor: rgba(255, 255, 255, 1);

        --cashierFavoriteAmountBonusBadgeBackgroundColor: rgba(255, 199, 0, 1);
        --cashierFavoriteAmountBonusBadgeColor: rgba(68, 80, 88, 1);

        --cashierCheckbox: rgba(12, 229, 160, 1);
        --cashierCheckboxFontSize: 12px;
        --cashierCheckboxFontWeight: 400;
        --cashierCheckboxLineHeight: 16px;
        --cashierCheckboxText: rgba(255, 255, 255, 1);
        --cashierCheckboxColor: rgba(48, 48, 48, 1);
        --cashierCheckboxMarkColor: rgba(0, 0, 0, 1);
        --cashierCheckboxBoxShadow: none;

        --cashierErrorsColorFont: rgba(248, 78, 78, 1);
        --cashierErrorsBackground: linear-gradient(0deg, rgba(13, 29, 52, 0.75) 0%, rgba(13, 29, 52, 0.75) 100%), #F84E4E;
        --cashierErrorsBorder: rgba(248, 78, 78, 1);
        --cashierErrorsBorderRadius: 4px;

        --cashierDropdownLabel: rgba(129, 142, 149, 1);
        --cashierDropdownPlaceholder: rgba(255, 255, 255, .5);
        --cashierDropdownBoxShadow: none;
        --cashierDropdownBackground: rgba(48, 48, 48, 1);
        --cashierDropdownColor: rgba(255, 255, 255, 1);
        --cashierDropdownOptionHover: rgba(129, 142, 149, 1);
        --cashierDropdownColorHover: rgba(255, 255, 255, 1);
        --cashierDropdownBorderRadius: 32px;
        --cashierDropdownActiveBorderColor: rgba(12, 229, 160, 1);
        --cashierDropdownBorderRadiusCorner: 32px;
        --cashierDropdownOptionsTopMargin: 4px;
        --cashierDropdownOptionsBoxShadow: none;

        --cashierNewAccountBackground: rgba(48, 48, 48, 1);
        --cashierNewAccountBoxShadow: none;

        --cashierDescriptionText: rgba(255, 255, 255, 1);
        --cashierIconColor: rgba(48, 48, 48, 1);
        --cashierLoaderColor: rgba(12, 229, 160, 1);
        --cashierArrowWrapperDisplay: none;
        --cashierArrowIconColor: none;
      }
    }
  }
}
